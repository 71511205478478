import React from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, ToolbarButtons } from 'ui/components';
import {
  getManutencaoVeiculoOS,
  createManutencaoVeiculoOS,
  printOSManutencaoVeiculoOS,
  deleteManutencaoVeiculoOS,
} from 'core/services/FRO/ordemServico_Manutencao';
import { getCondicaoPagamentoAutoComplete } from 'core/services/FIN/condicaoPagamento';
import Oficina from 'core/models/FRO/oficina';
import {
  getVeiculoManutencaoPreventivaPendentesByVeiculo,
  getVeiculoManutencaoPreventivaPendentesByListaDeVeiculos,
} from 'core/services/FRO/veiculo_ManutencaoPreventiva';
import ManutencaoVeiculoPlaca from 'core/models/FRO/manutencaoVeiculoPlaca';
import Veiculo from 'core/models/FRO/veiculo';
import { printManutencaoVeiculo } from 'core/services/FRO/manutencaoVeiculo';
import { getParametro } from 'core/services/SEG/parametro';
import { getTipoDespesa } from 'core/services/FIN';

import ManutencaoInfosPrincipal from './ManutencaoInfosPrincipal';
import ManutencaoInfosVeiculos from './ManutencaoInfosVeiculos';
import ManutencaoInfosProdutos from './manutencaoInfosProdutos';
import { ModalVincularPneuVeiculo } from './modalVincularPneuVeiculo.tsx';
import ManutencaoInfosFaturas from './ManutencaoInfosFaturas';

const dateOnlyFormat = (str) => {
  if (str) {
    const date = new Date(str);
    const f = new Intl.DateTimeFormat('pt-br', {
      dateStyle: 'short',
    });

    return f.format(date);
  }

  return '';
};

export default function ManutencaoVeiculoOSItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
  onOpenReport,
  findTransaction,
  onOpenTransaction,
}) {
  const { id: idSelecao } = PageTypes.Selection;

  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState(null);
  const [dataProduto, setDataProduto] = React.useState({
    vlrUnitarioInicial: 0,
  });
  const [dataFatura, setDataFatura] = React.useState({ temParametro: false });
  const [dataVeiculo, setDataVeiculo] = React.useState({});
  // const [dataPneuVeiculo, setDataPneuVeiculo] = React.useState({});
  const [preventivasList, setPreventivasList] = React.useState([]);
  const [registraDataMovimento, setRegistraDataMovimento] = React.useState('N');
  const [data, setData] = React.useState({
    pessoaOfi: new Oficina({ nrSeqPessoaOfi: null }),
    flgOficinaInterna: false,
    produtos: [],
    faturas: [],
    placas: [],
    veiculo: new Veiculo({}),
    manutencoesPreventivas: [],
    vinculaPneuPanelOn: false,
    flgImpressao: false,
    vlrDeslocamento: 0,
    vlrSomaProdutos: 0,
  });
  const [modalShow, setModalShow] = React.useState({
    showVincularPneuVeiculo: false,
  });

  const prevDataRef = React.useRef();
  const formSubmit = React.useRef();
  const gridViewVeiculos = React.useRef();
  const gridViewManutencaoPreventivaOnNew = React.useRef();
  const gridViewManutencaoPreventiva = React.useRef();
  const gridViewFaturas = React.useRef();
  const gridViewProdutos = React.useRef();

  React.useEffect(() => {
    prevDataRef.current = data;
  }, [data]);

  const validaSeExisteValor = (valor) => {
    if (valor === undefined || valor === null) {
      return false;
    }

    return true;
  };

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onPrint = async (obj) => {
    setLoading(true);

    if ('nrSeqManutencaoVeiculoOS' in obj) {
      const { value } = await printOSManutencaoVeiculoOS(obj);

      onOpenReport(transaction.noTransacao, value);
    } else {
      const { value } = await printOSManutencaoVeiculoOS(data);

      onOpenReport(transaction.noTransacao, value);
    }

    setLoading(false);
  };

  const onPrintManutencao = async (e) => {
    setLoading(true);

    const { value } = await printManutencaoVeiculo({
      nrSeqManutencaoVeiculo: e,
    });

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onClickPrint = () =>
    data?.flgImpressao && !data?.pessoaOfi?.flgControlaEstoque
      ? onPrint(data)
      : null;

  const onNew = async () => {
    setLoading(true);
    formSubmit.current.reset();

    const sessionUser = JSON.parse(sessionStorage.getItem('@dataAuth')).user;

    const { condicoesPagamento } = await getCondicaoPagamentoAutoComplete();

    const today = new Date().toJSON().slice(0, 10);

    setData({
      usuarioSol: sessionUser,
      nrSeqUsuarioSol: sessionUser.nrSeqUsuario,
      usuarioAut: sessionUser,
      nrSeqUsuarioAut: sessionUser.nrSeqUsuario,
      condicaoPagamento: condicoesPagamento[0],
      nrSeqCondicaoPagamento: condicoesPagamento[0].nrSeqCondicaoPagamento,
      dtAbertura: today,
      flgOficinaInterna: false,
      pessoaOfi: new Oficina({
        nrSeqPessoaOfi: null,
      }),
      produtos: [],
      faturas: [],
      placas: [],
      veiculo: new Veiculo({}),
      manutencoesPreventivas: [],
      flgConcluido: false,
      flgStatus: 'Pendente',
      flgImpressao: false,
      vlrDeslocamento: 0,
      vlrSomaProdutos: 0,
      sessionUser,
    });
    setDataProduto({
      dtMovimento: null,
      obs: '',
      vlrUnitario: null,
      vlrUnitarioInicial: 0,
      quantidade: null,
      estoque: null,
    });
    setRegistraDataMovimento('N');

    if (gridViewVeiculos && gridViewVeiculos.current) {
      gridViewVeiculos.current.setDataSource([]);
    }

    if (
      gridViewManutencaoPreventivaOnNew &&
      gridViewManutencaoPreventivaOnNew.current
    ) {
      gridViewManutencaoPreventivaOnNew.current.setDataSource([]);
    }

    if (gridViewFaturas && gridViewFaturas.current) {
      gridViewFaturas.current.setDataSource([]);
    }

    if (gridViewProdutos && gridViewProdutos.current) {
      gridViewProdutos.current.setDataSource([]);
    }

    setLoading(false);
  };

  const onPreencheGridVeiculosOnLoad = (dataRes, veiculo) => {
    const veiculosArray = [];

    const newManutencaoVeiculoPlaca = new ManutencaoVeiculoPlaca({
      veiculo,
      placa: veiculo.placa,
      nrSeqVeiculo: veiculo.nrSeqVeiculo,
      nrOdometro: dataRes.nrOdometro,
      ultimaDataNoSistema: dataRes.dtAbertura,
    });

    veiculosArray.push(newManutencaoVeiculoPlaca);

    const veiculoArray = [...veiculo.veiculosVinculados];

    veiculoArray.forEach(async (item) => {
      const novoManutencaoVeiculoPlaca = new ManutencaoVeiculoPlaca({
        veiculo: item,
        placa: item.placa,
        nrSeqVeiculo: item.nrSeqVeiculo,
        nrOdometro: item.nrOdometro,
        ultimaDataNoSistema: dataRes.dtAbertura,
      });

      veiculosArray.push(novoManutencaoVeiculoPlaca);
    });

    return veiculosArray;
  };

  const buscaTipoDespesaPadrao = async (oficina) => {
    const parametro = await getParametro('TIPODESPESAMANUTENCAOVEICULO');

    if (parametro.noConteudo !== '') {
      const tipoDespesaParam = await getTipoDespesa(
        Number.parseInt(parametro.noConteudo, 10)
      );

      if (tipoDespesaParam.nrSeqTipoDespesa) {
        setDataFatura({
          ...dataFatura,
          temParametro: true,
          tipoDespesa: tipoDespesaParam,
          nrSeqTipoDespesa: tipoDespesaParam.nrSeqTipoDespesa,
        });
      }
    } else if (oficina?.nrSeqTipoDespesa) {
      const tipoDespesaOficina = await getTipoDespesa(
        oficina?.nrSeqTipoDespesa
      );

      if (tipoDespesaOficina?.nrSeqTipoDespesa) {
        setDataFatura({
          ...dataFatura,
          temParametro: false,
          tipoDespesa: tipoDespesaOficina,
          nrSeqTipoDespesa: tipoDespesaOficina.nrSeqTipoDespesa,
        });
      }
    }
  };

  const load = React.useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      formSubmit.current.reset();

      const sessionUser = JSON.parse(sessionStorage.getItem('@dataAuth')).user;

      const { status, message: msg, data: res } = await getManutencaoVeiculoOS(
        registryKey
      );

      if (Object.keys(res).length > 0) {
        if (res.flgConcluido) {
          res.flgStatus = 'Concluído';
          res.flgImpressao = true;
        } else {
          res.flgStatus = 'Pendente';
          res.flgImpressao = false;
        }

        await buscaTipoDespesaPadrao(res.pessoaOfi);

        // Se já existir uma Manutencao Veículo
        if (res.nrSeqManutencaoVeiculo) {
          const somaProdutos = res.produtos.reduce(
            (accumulator, currentValue) => accumulator + currentValue.vlrTotal,
            0
          );

          // setData + tipoOficina + sessionUser
          if (res.pessoaOfi?.flgControlaEstoque) {
            setData({
              ...res,
              flgOficinaInterna: true,
              vlrSomaProdutos: somaProdutos,
              sessionUser,
            });

            if (res.flgRegistraDataMovimento) {
              setRegistraDataMovimento('S');
            }
          } else {
            setData({
              ...res,
              flgOficinaInterna: false,
              vlrSomaProdutos: somaProdutos,
              sessionUser,
            });
            setRegistraDataMovimento('N');
          }

          // Veiculos
          if (res.placas && gridViewVeiculos && gridViewVeiculos.current) {
            const gridList = res.placas.map((item) => {
              if (res.flgConcluido) {
                item.flgExibeBtn = false;
              } else {
                item.flgExibeBtn = true;
              }

              if (item.veiculo.veiculoModelo?.noTipoOdometro === 'H') {
                item.noTipoOdometro = 'Horímetro';
                item.nrOdometro = (item.nrOdometro / 60).toFixed(1);
              }

              return item;
            });
            gridViewVeiculos.current.setDataSource(gridList);
          }
          setDataVeiculo({});

          // Preventivas afetadas
          if (
            res.manutencoesPreventivas &&
            gridViewManutencaoPreventiva &&
            gridViewManutencaoPreventiva.current
          ) {
            const gridList = res.manutencoesPreventivas.map((item) => {
              if (res.flgConcluido) {
                item.flgExibeBtn = false;
              } else {
                item.flgExibeBtn = true;
              }

              return item;
            });
            gridViewManutencaoPreventiva.current.setDataSource(gridList);
          }

          // Manutenções Preventivas
          if (!res.flgConcluido) {
            const listaVeiculos = [];

            for (let i = 0; i < res.placas.length; i += 1) {
              const objBusca = {
                nrSeqVeiculo: res.placas[i].nrSeqVeiculo,
                odometroAtual: res.placas[i].nrOdometro,
                dtDocumento: res.dtAbertura,
              };

              listaVeiculos.push(objBusca);
            }

            const {
              data: manutencoesPreventivas,
            } = await getVeiculoManutencaoPreventivaPendentesByListaDeVeiculos(
              listaVeiculos
            );

            if (manutencoesPreventivas) {
              manutencoesPreventivas.forEach((preventiva) => {
                if (
                  preventiva.manutencaoPreventiva.tipoIntervalo.flgTipo ===
                  false
                ) {
                  preventiva.odometroRestanteManutencao = `${preventiva.odometroRestanteManutencao} KM`;
                } else {
                  preventiva.odometroRestanteManutencao = `${preventiva.odometroRestanteManutencao} dias`;
                }
              });

              setPreventivasList(manutencoesPreventivas);

              if (
                gridViewManutencaoPreventivaOnNew &&
                gridViewManutencaoPreventivaOnNew.current
              ) {
                gridViewManutencaoPreventivaOnNew.current.setDataSource(
                  manutencoesPreventivas
                );
              }
            }
          } else {
            setPreventivasList([]);
          }

          // Faturas
          if (res.faturas && gridViewFaturas && gridViewFaturas.current) {
            const gridList = res.faturas.map((item) => {
              if (res.flgConcluido) {
                item.flgExibeBtn = false;
              } else {
                item.flgExibeBtn = true;
              }

              return item;
            });
            gridViewFaturas.current.setDataSource(gridList);
          }

          // Produtos
          if (res.produtos && gridViewProdutos && gridViewProdutos.current) {
            const gridList = res.produtos.map((item) => {
              if (res.flgConcluido) {
                item.flgExibeBtn = false;
              } else {
                item.flgExibeBtn = true;
              }

              return item;
            });
            gridViewProdutos.current.setDataSource(gridList);
          }
        }
        setDataProduto({});

        // Se não existir uma Manutencao Veículo
        if (!res.nrSeqManutencaoVeiculo) {
          const veiculos = onPreencheGridVeiculosOnLoad(res, res.veiculo);
          res.placas = veiculos;

          // setData + tipoOficina + sessionUser
          if (res.pessoaOfi?.flgControlaEstoque) {
            setData({
              ...res,
              flgOficinaInterna: true,
              sessionUser,
            });
          } else {
            setData({
              ...res,
              flgOficinaInterna: false,
              sessionUser,
            });
          }

          // Veículo
          if (veiculos && gridViewVeiculos && gridViewVeiculos.current) {
            const gridList = veiculos.map((item) => {
              if (res.flgConcluido) {
                item.flgExibeBtn = false;
              } else {
                item.flgExibeBtn = true;
              }

              return item;
            });
            gridViewVeiculos.current.setDataSource(gridList);
          }
          setDataVeiculo({});

          // Manutenções Preventivas
          if (!res.flgConcluido) {
            const {
              data: manutencoesPreventivas,
            } = await getVeiculoManutencaoPreventivaPendentesByVeiculo({
              nrSeqVeiculo: res.nrSeqVeiculo,
              odometroAtual: res.nrOdometro,
              dtDocumento: res.dtAbertura,
            });

            if (manutencoesPreventivas) {
              manutencoesPreventivas.forEach((preventiva) => {
                if (
                  preventiva.manutencaoPreventiva.tipoIntervalo.flgTipo ===
                  false
                ) {
                  preventiva.odometroRestanteManutencao = `${preventiva.odometroRestanteManutencao} KM`;
                } else {
                  preventiva.odometroRestanteManutencao = `${preventiva.odometroRestanteManutencao} dias`;
                }
              });

              setPreventivasList(manutencoesPreventivas);

              if (
                gridViewManutencaoPreventivaOnNew &&
                gridViewManutencaoPreventivaOnNew.current
              ) {
                gridViewManutencaoPreventivaOnNew.current.setDataSource(
                  manutencoesPreventivas
                );
              }
            }
          }

          // Produtos
          if (res.produtos && gridViewProdutos && gridViewProdutos.current) {
            const gridList = res.produtos.map((item) => {
              if (res.flgConcluido) {
                item.flgExibeBtn = false;
              } else {
                item.flgExibeBtn = true;
              }

              return item;
            });
            gridViewProdutos.current.setDataSource(gridList);
          }
          setDataProduto({});
        }
      } else {
        onSetMessage(status, msg);
        onNew();
      }

      setLoading(false);
    } else {
      onNew();
      setMessage(null);
    }
  }, [registryKey]);

  React.useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);

  React.useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const validateVlrFechamentoOS = () => {
    if (!data.pessoaOfi.flgControlaEstoque && data.pessoaOfi.flgContasPagar) {
      if (data.faturas.length < 1 && data.vlrFechamentoOS > 0) {
        return { isValid: false, validationMsg: 'Nenhuma fatura inserida.' };
      }

      const valorFaturas = data.faturas.reduce(
        (accumulator, currentValue) => accumulator + currentValue.vlrParcela,
        0
      );

      if (valorFaturas !== data.vlrFechamentoOS) {
        return {
          isValid: false,
          validationMsg:
            'Soma das faturas não coincidem com o valor de fechamento.',
        };
      }
    }

    if (data.vlrSomaProdutos + data.vlrDeslocamento !== data.vlrFechamentoOS) {
      return {
        isValid: false,
        validationMsg:
          'Soma dos produtos e do deslocamento não coincidem com o valor de fechamento.',
      };
    }

    return { isValid: true };
  };

  const converteHorimetro = (horimetro) => {
    if (horimetro > 0) {
      const valoresOdometro = horimetro.toString().split('.');

      const valorHora = valoresOdometro[0] * 60;
      const valorMinuto = valoresOdometro[1] * 6;

      const odometro = valorHora + valorMinuto;
      return Number.parseInt(odometro, 10);
    }

    return 0;
  };

  const onSave = async () => {
    const { isValid, validationMsg } = validateVlrFechamentoOS();

    if (!data.pessoaOfi.flgControlaEstoque && data.flgConcluido && !isValid) {
      onSetMessage(ResponseStatus.Error, validationMsg);
    } else {
      setLoading(true);
      const sessionUser = JSON.parse(sessionStorage.getItem('@dataAuth')).user;

      if (data.nrSeqManutencaoVeiculoOS) {
        data.usuarioAlt = sessionUser;
        data.nrSeqUsuarioAlt = sessionUser.nrSeqUsuario;
      } else {
        data.usuarioCad = sessionUser;
        data.nrSeqUsuarioCad = sessionUser.nrSeqUsuario;
      }

      if (data.flgOficinaInterna) {
        data.dtSaida = null;
        data.dtPrevistaRetorno = null;
        data.condicaoPagamento = null;
        data.nrSeqCondicaoPagamento = null;
        data.faturas = null;
        data.vlrDesconto = null;
        data.vlrOs = data.vlrSomaProdutos;
        data.vlrFechamentoOS = data.vlrSomaProdutos;
      }

      const preventArray = [...data.manutencoesPreventivas];
      if (data.placas.length > 0) {
        if (gridViewManutencaoPreventivaOnNew.current) {
          const selecteds = gridViewManutencaoPreventivaOnNew.current.getCheckBoxValuesAt(
            0
          );

          selecteds.forEach((item) => {
            const nrSeqItem = item[0].value;

            const itemFounded = preventivasList.find(
              (manutencaoPreventiva) =>
                manutencaoPreventiva.nrSeqVeiculo_ManutencaoPrevent ===
                nrSeqItem
            );

            itemFounded.status = 'Afetado';

            if (data.nrSeqManutencaoVeiculo > 0) {
              itemFounded.nrSeqManutencaoVeiculo = data.nrSeqManutencaoVeiculo;
            }

            preventArray.push(itemFounded);
          });
        }
      }

      if (data.placas.length === 0) {
        data.nrSeqVeiculo = null;
      }

      if (data.vlrOs === undefined || data.vlrOs === null) {
        data.vlrOs = 0;
      }

      if (data.vlrFechamentoOS === undefined || data.vlrFechamentoOS === null) {
        data.vlrFechamentoOS = 0;
      }

      if (data.vlrDeslocamento === undefined || data.vlrDeslocamento === null) {
        data.vlrDeslocamento = 0;
      }

      if (data.vlrDesconto === undefined || data.vlrDesconto === null) {
        data.vlrDesconto = 0;
      }

      const placasObj = data.placas.map((item) => {
        const isPrincipal =
          item.flgPrincipal === null || item.flgPrincipal === undefined
            ? item.veiculo.veiculoTipo.flgPrincipal
            : item.flgPrincipal;

        if (item.noTipoOdometro === 'Horímetro') {
          item.nrOdometro = converteHorimetro(item.nrOdometro);
        }

        return new ManutencaoVeiculoPlaca({
          flgPrincipal: isPrincipal,
          nrSeqVeiculo: item.nrSeqVeiculo,
          nrOdometro: item.nrOdometro,
          nrSeqManutencaoVeiculo: item.nrSeqManutencaoVeiculo,
          status: item.status,
        });
      });

      let flgRegistraDataMovimento = false;
      if (data.flgOficinaInterna && registraDataMovimento === 'S') {
        flgRegistraDataMovimento = true;
      }

      if (data.veiculo.veiculoModelo?.noTipoOdometro === 'H') {
        data.nrOdometro = converteHorimetro(data.nrOdometro);
      }

      const obj = {
        cdManutencaoVeiculoOS: data.cdManutencaoVeiculoOS,
        dtAbertura: data.dtAbertura,
        dtCad: data.dtCad,
        dtFechamento: data.dtFechamento,
        dtPrevistaRetorno: data.dtPrevistaRetorno,
        dtSaida: data.dtSaida,
        faturas: data.faturas,
        flgConcluido: data.flgConcluido,
        horaAbertura: data.horaAbertura,
        horaFechamento: data.horaFechamento,
        manutencoesPreventivas: data.manutencoesPreventivas,
        nrOdometro: data.nrOdometro,
        nrSeqCondicaoPagamento: data.nrSeqCondicaoPagamento,
        nrSeqManutencaoVeiculo: data.nrSeqManutencaoVeiculo,
        nrSeqManutencaoVeiculoOS: data.nrSeqManutencaoVeiculoOS,
        nrSeqPessoaOfi: data.nrSeqPessoaOfi,
        nrSeqUsuarioAlt: sessionUser.nrSeqUsuario,
        nrSeqUsuarioAut: data.nrSeqUsuarioAut,
        nrSeqUsuarioCad: data.nrSeqUsuarioCad,
        nrSeqUsuarioSol: data.nrSeqUsuarioSol,
        nrSeqVeiculo: data.nrSeqVeiculo,
        obsOS: data.obsOS,
        pessoaOfi: data.pessoaOfi,
        placas: placasObj,
        produtos: data.produtos,
        status: data.status,
        usuarioAlt: sessionUser,
        usuarioAut: data.usuarioAut,
        usuarioCad: data.usuarioCad,
        usuarioSol: data.usuarioSol,
        veiculo: data.veiculo,
        vlrDesconto: data.vlrDesconto,
        vlrDeslocamento: data.vlrDeslocamento,
        vlrFechamentoOS: data.vlrFechamentoOS,
        vlrOs: data.vlrOs,
        nrTituloPagar: data.nrTituloPagar,
        flgRegistraDataMovimento,
      };

      const {
        status,
        message: msg,
        value: ordemServicoManut,
      } = await createManutencaoVeiculoOS({
        ...obj,
        manutencoesPreventivas: preventArray,
      });

      if (status === ResponseStatus.Success) {
        if (data?.flgOficinaInterna && ordemServicoManut?.flgGeraImpressaoPdf) {
          await onPrintManutencao(ordemServicoManut.nrSeqManutencaoVeiculo);
        }

        onNew();
      } else {
        setData(data);
      }

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      setLoading(false);
    }
  };

  const onClickSave = () => {
    onSave();
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteManutencaoVeiculoOS(
        registryKey
      );

      if (status === ResponseStatus.Success) {
        formSubmit.current.reset();
        onNew();
      } else {
        setData(data);
      }

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onClickExcluir = () =>
    data?.nrSeqManutencaoVeiculoOS > 0 ? onExcluir() : null;

  return (
    <CSDManutPage
      title='Manutenção - Ordem de Serviço de Manutenção Veículo'
      isActive={isActive}
      loading={loading}
      onMessagerClose={() => setMessage(null)}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => onNew()}
      onSave={onClickSave}
      onDelete={onClickExcluir}
      onPrint={onClickPrint}
      message={message}
      ref={formSubmit}
      transaction={transaction}
    >
      <ToolbarButtons>
        {data?.nrSeqManutencaoVeiculo && (
          <ToolbarButtons.Button
            text='Imprimir Manutenção'
            onClick={() => onPrintManutencao(data.nrSeqManutencaoVeiculo)}
          />
        )}
      </ToolbarButtons>

      <ManutencaoInfosPrincipal
        onSetMessage={onSetMessage}
        data={data}
        setData={setData}
        registraDataMovimento={registraDataMovimento}
        setRegistraDataMovimento={setRegistraDataMovimento}
        gridViewVeiculos={gridViewVeiculos}
        validaSeExisteValor={validaSeExisteValor}
        gridViewProdutos={gridViewProdutos}
        dataFatura={dataFatura}
        buscaTipoDespesaPadrao={buscaTipoDespesaPadrao}
      />

      <ManutencaoInfosVeiculos
        setLoading={setLoading}
        onSetMessage={onSetMessage}
        setMessage={setMessage}
        prevDataRef={prevDataRef}
        data={data}
        setData={setData}
        dataVeiculo={dataVeiculo}
        setDataVeiculo={setDataVeiculo}
        setPreventivasList={setPreventivasList}
        gridViewVeiculos={gridViewVeiculos}
        gridViewManutencaoPreventiva={gridViewManutencaoPreventiva}
        gridViewManutencaoPreventivaOnNew={gridViewManutencaoPreventivaOnNew}
      />

      <ManutencaoInfosFaturas
        setLoading={setLoading}
        onSetMessage={onSetMessage}
        prevDataRef={prevDataRef}
        data={data}
        setData={setData}
        dataFatura={dataFatura}
        setDataFatura={setDataFatura}
        gridViewFaturas={gridViewFaturas}
      />

      <ManutencaoInfosProdutos
        setLoading={setLoading}
        onSetMessage={onSetMessage}
        prevDataRef={prevDataRef}
        data={data}
        setData={setData}
        dataProduto={dataProduto}
        setDataProduto={setDataProduto}
        registraDataMovimento={registraDataMovimento}
        preventivasList={preventivasList}
        validaSeExisteValor={validaSeExisteValor}
        gridViewProdutos={gridViewProdutos}
        gridViewManutencaoPreventivaOnNew={gridViewManutencaoPreventivaOnNew}
        findTransaction={findTransaction}
        onOpenTransaction={onOpenTransaction}
        setModalShow={setModalShow}
      />

      {/* Linha de usuário cadastrado */}
      {!!data?.nrSeqManutencaoVeiculoOS && (
        <div className='row mx-1'>
          <p className='bg-inactive py-1'>
            {' '}
            Cadastrado por: {data.usuarioCad.noLogin} -{' '}
            {dateOnlyFormat(data.dtCad)}
          </p>
        </div>
      )}
      {!!data?.nrSeqManutencaoVeiculoOS && data.nrSeqUsuarioAlt && (
        <div className='row mx-1'>
          <p className='bg-inactive py-1'>
            {' '}
            Alterado por: {data.usuarioAlt.noLogin} -{' '}
            {dateOnlyFormat(data.dtAlteracao)}
          </p>
        </div>
      )}

      <ModalVincularPneuVeiculo
        show={modalShow?.showVincularPneuVeiculo}
        data={modalShow?.pneuVeiculo}
        setLoading={setLoading}
        onClose={() =>
          setModalShow({
            showVincularPneuVeiculo: false,
          })
        }
      />
    </CSDManutPage>
  );
}
