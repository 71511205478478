import React, { useRef, useEffect } from 'react';

import { GridView } from 'ui/components';
import { Theme, BootstrapSizes } from 'ui/Helpers/utils';

export default function GridRelatorios({
  nrSeqTipoImpressao,
  transaction,
  onColumnSort,
  gridColuns,
  onClickOpenViagem,
  onClickImprimirCte,
  sumFields,
}) {
  const gridView = useRef();

  useEffect(() => {
    if (gridView && gridView.current)
      gridView.current.setDataSource(gridColuns.data, gridColuns.pagination);
  }, [gridColuns]);
  const columns1 = [
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'dtEmissao',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    { key: 'noPlaca', title: 'Cavalo' },
    {
      key: 'noPlacaComp',
      title: 'Carreta',
    },
    {
      key: 'cdColetaEmbarque',
      title: 'NrCte',
    },
    {
      key: 'statusCTEstr',
      title: 'Status CTE',
      bgClassProperty: 'noColorStatus',
    },
    {
      key: 'nrNotaFiscal',
      title: 'Nota',
    },
    {
      key: 'vlrTotal',
      title: 'Vlr Frete',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrPedagio',
      title: 'Vlr Pedagio',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrMercadoria',
      title: 'Vlr Mercadoria',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'noMotorista',
      title: 'Motorista',
    },
    {
      key: 'pesoBruto',
      title: 'Peso',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'noRemetente',
      title: 'Remetente',
    },
    {
      key: 'noUFColeta',
      title: 'UF',
    },
    {
      key: 'noCidadeColeta',
      title: 'Local Coleta',
    },
    {
      key: 'noDestinatario',
      title: 'Destinatario',
    },
    {
      key: 'noUFEntrega',
      title: 'UF',
    },
    {
      key: 'noCidadeEntrega',
      title: 'Local Entrega',
    },
    {
      key: 'flgCancelado',
      title: 'Canc',
      format: GridView.DataTypes.Boolean,
    },
    {
      key: 'noTipoFrete',
      title: 'TipoFrete',
    },
    {
      key: 'nrApolice',
      title: 'Apolice',
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
  ];
  const columns2 = [
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'dtEmissao',
      title: 'Mes/Ano',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'noUFEntrega',
      title: 'Estado Entrega',
    },
    {
      key: 'noCidadeEntrega',
      title: 'Municipio',
    },
    {
      key: 'pesoBruto',
      title: 'Peso',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrTotal',
      title: 'BC',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrIcms',
      title: 'Vlr Icms',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrTotal',
      title: 'Vlr Total',
      format: GridView.DataTypes.Money,
    },
  ];
  const columns3 = [
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'dtEmissao',
      title: 'Mes/Ano',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'noUFEntrega',
      title: 'Estado Entrega',
    },
    {
      key: 'noCidadeEntrega',
      title: 'Municipio',
    },
    {
      key: 'pesoBruto',
      title: 'Peso',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrTotal',
      title: 'BC',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrIcms',
      title: 'Vlr Icms',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrTotal',
      title: 'Vlr Total',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
  ];
  const columns4 = [
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'dtEmissao',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrNotaFiscal',
      title: 'Nota',
    },
    {
      key: 'cdColetaEmbarque',
      title: 'NrCte',
    },
    {
      key: 'statusCTEstr',
      title: 'Status CTE',
      bgClassProperty: 'noColorStatus',
    },
    {
      key: 'vlrTotal',
      title: 'Vlr Frete',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrMercadoria',
      title: 'Vlr Mercadoria',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'noUFColeta',
      title: 'UF',
    },
    {
      key: 'noCidadeColeta',
      title: 'Local Coleta',
    },
    {
      key: 'noDestinatario',
      title: 'Destinatario',
    },
    {
      key: 'noUFEntrega',
      title: 'Estado Entrega',
    },
    {
      key: 'noCidadeEntrega',
      title: 'Municipio',
    },
    {
      key: 'flgCancelado',
      title: 'Canc',
      format: GridView.DataTypes.Boolean,
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
  ];
  const columns5 = [
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'noCliente',
      title: 'Cliente',
    },
    {
      key: 'nrClienteDocumento',
      title: 'CNPJ',
    },
    {
      key: 'vlrTotal',
      title: 'Vlr Total',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrMercadoria',
      title: 'Vlr Mercadoria Transportada',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'pesoBruto',
      title: 'Peso Transportado',
      format: GridView.DataTypes.Decimal,
    },
  ];
  const columns6 = [
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'dtEmissao',
      title: 'Mes/Ano',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'cdColetaEmbarque',
      title: 'NrCte',
    },
    {
      key: 'statusCTEstr',
      title: 'Status CTE',
      bgClassProperty: 'noColorStatus',
    },
    {
      key: 'noColetaEmbarqueComplementar',
      title: 'Cte Complementar',
    },
    { key: 'noPlaca', title: 'Placa' },
    {
      key: 'quantidadeNotas',
      title: 'Quant. Notas',
    },
    {
      key: 'qtdeEmbarque',
      title: 'Quant. Volumes',
    },
    {
      key: 'pesoBruto',
      title: 'Peso Bruto',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrTotal',
      title: 'Vlr Total',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
  ];
  const columns7 = [
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'dtEmissao',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    { key: 'noPlaca', title: 'Placa' },
    {
      key: 'noMotorista',
      title: 'Motorista',
    },
    {
      key: 'cdColetaEmbarque',
      title: 'NrCte',
    },
    {
      key: 'statusCTEstr',
      title: 'Status CTE',
      bgClassProperty: 'noColorStatus',
    },
    {
      key: 'nrNotaFiscal',
      title: 'Nota',
    },
    {
      key: 'vlrTotal',
      title: 'Vlr Frete',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrMercadoria',
      title: 'Vlr Mercadoria',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'pesoBruto',
      title: 'Peso Transportado',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'noRemetente',
      title: 'Remetente',
    },
    {
      key: 'noUFColeta',
      title: 'UF',
    },
    {
      key: 'noCidadeColeta',
      title: 'Local Coleta',
    },
    {
      key: 'noDestinatario',
      title: 'Destinatario',
    },
    {
      key: 'noUFEntrega',
      title: 'UF',
    },
    {
      key: 'noCidadeEntrega',
      title: 'Local Entrega',
    },
    {
      key: 'qtdeEmbarque',
      title: 'Quant',
    },
    {
      key: 'noEspecieFrete',
      title: 'Produto',
    },
    {
      key: 'flgCancelado',
      title: 'Canc',
      format: GridView.DataTypes.Boolean,
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
  ];
  const columns8 = [
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'dtEmissao',
      title: 'Mes/Ano',
      format: GridView.DataTypes.Date,
    },
    { key: 'noEspecieFrete', title: 'Produto' },
    {
      key: 'qtdeEmbarque',
      title: 'Quantidade',
    },
    {
      key: 'vlrTotal',
      title: 'Vlr Frete',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
  ];
  const columns9 = [
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'dtEmissao',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    { key: 'noPlaca', title: 'Cavalo' },
    {
      key: 'noPlacaComp',
      title: 'Carreta',
    },
    {
      key: 'cdColetaEmbarque',
      title: 'NrCte',
    },
    {
      key: 'statusCTEstr',
      title: 'Status CTE',
      bgClassProperty: 'noColorStatus',
    },
    {
      key: 'nrNotaFiscal',
      title: 'Nota',
    },
    {
      key: 'vlrTotal',
      title: 'Vlr Frete',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrMercadoria',
      title: 'Vlr Mercadoria',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'noMotorista',
      title: 'Motorista',
    },
    {
      key: 'pesoBruto',
      title: 'Peso',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'noCliente',
      title: 'Cliente',
    },
    {
      key: 'noRemetente',
      title: 'Remetente',
    },
    {
      key: 'noUFColeta',
      title: 'UF',
    },
    {
      key: 'noCidadeColeta',
      title: 'Local Coleta',
    },
    {
      key: 'noDestinatario',
      title: 'Destinatario',
    },
    {
      key: 'noUFEntrega',
      title: 'UF',
    },
    {
      key: 'noCidadeEntrega',
      title: 'Local Entrega',
    },
    {
      key: 'flgCancelado',
      title: 'Canc',
      format: GridView.DataTypes.Boolean,
    },
    {
      key: 'noTipoFrete',
      title: 'TipoFrete',
    },
    {
      key: 'nrApolice',
      title: 'Apolice',
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqViagem',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickOpenViagem(e),
      theme: Theme.Success,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Exibir Viagem',
      tooltipDirection: 'bottom',
    },
  ];
  const columns10 = [
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'dtEmissao',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    { key: 'noPlaca', title: 'Placa' },
    {
      key: 'noMotorista',
      title: 'Motorista',
    },
    {
      key: 'cdColetaEmbarque',
      title: 'NrCte',
    },
    {
      key: 'statusCTEstr',
      title: 'Status CTE',
      bgClassProperty: 'noColorStatus',
    },
    {
      key: 'nrNotaFiscal',
      title: 'Nota',
    },
    {
      key: 'vlrTotal',
      title: 'Vlr Frete',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrMercadoria',
      title: 'Vlr Mercadoria',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'pesoBruto',
      title: 'Peso Transportado',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'noRemetente',
      title: 'Remetente',
    },
    {
      key: 'noUFColeta',
      title: 'UF',
    },
    {
      key: 'noCidadeColeta',
      title: 'Local Coleta',
    },
    {
      key: 'noDestinatario',
      title: 'Destinatario',
    },
    {
      key: 'noUFEntrega',
      title: 'UF',
    },
    {
      key: 'noCidadeEntrega',
      title: 'Local Entrega',
    },
    {
      key: 'qtdeEmbarque',
      title: 'Quant',
    },
    {
      key: 'noEspecieFrete',
      title: 'Produto',
    },
    {
      key: 'flgCancelado',
      title: 'Canc',
      format: GridView.DataTypes.Boolean,
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
  ];
  const columns11 = [
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    { key: 'noPlaca', title: 'Placa' },
    {
      key: 'dtEmissao',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'cdColetaEmbarque',
      title: 'NrCte',
    },
    {
      key: 'statusCTEstr',
      title: 'Status CTE',
      bgClassProperty: 'noColorStatus',
    },
    {
      key: 'pesoBruto',
      title: 'Peso Transportado',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrTotal',
      title: 'Vlr Total',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
  ];
  const columns12 = [
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'dtEmissao',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'flgExisteArquivo',
      title: 'Existe Arquivo',
      format: GridView.DataTypes.Boolean,
    },
    {
      key: 'nrChaveCTE',
      title: 'Nr Chave CTE',
    },
    {
      key: 'cdColetaEmbarque',
      title: 'NrCte',
    },
    {
      key: 'statusCTEstr',
      title: 'Status CTE',
      bgClassProperty: 'noColorStatus',
    },
    {
      key: 'nrNotaFiscal',
      title: 'Nota',
    },
    {
      key: 'vlrTotal',
      title: 'Vlr Frete',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrMercadoria',
      title: 'Vlr Mercadoria',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'pesoBruto',
      title: 'Peso',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'noRemetente',
      title: 'Remetente',
    },
    {
      key: 'noUFColeta',
      title: 'UF',
    },
    {
      key: 'noCidadeColeta',
      title: 'Local Coleta',
    },
    {
      key: 'noDestinatario',
      title: 'Destinatario',
    },
    {
      key: 'noUFEntrega',
      title: 'UF',
    },
    {
      key: 'flgCancelado',
      title: 'Canc',
      format: GridView.DataTypes.Boolean,
    },
    {
      key: 'noTipoFrete',
      title: 'TipoFrete',
    },
    {
      key: 'tipoEmissaoCTE',
      title: 'tipo Emissao',
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
  ];
  const columns13 = [
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'dtEmissao',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    { key: 'noPlaca', title: 'Cavalo' },
    {
      key: 'noMotorista',
      title: 'Motorista',
    },
    {
      key: 'cdColetaEmbarque',
      title: 'NrCte',
    },
    {
      key: 'statusCTEstr',
      title: 'Status CTE',
      bgClassProperty: 'noColorStatus',
    },
    {
      key: 'nrNotaFiscal',
      title: 'Nota',
    },
    {
      key: 'noEspecieFrete',
      title: 'Produto',
    },
    {
      key: 'vlrTotal',
      title: 'Vlr Frete',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrMercadoria',
      title: 'Vlr Mercadoria',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'pesoBruto',
      title: 'Peso',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'noRemetente',
      title: 'Remetente',
    },
    {
      key: 'noUFColeta',
      title: 'UF',
    },
    {
      key: 'noCidadeColeta',
      title: 'Local Coleta',
    },
    {
      key: 'noDestinatario',
      title: 'Destinatario',
    },
    {
      key: 'noUFEntrega',
      title: 'UF',
    },
    {
      key: 'noCidadeEntrega',
      title: 'Local Entrega',
    },
    {
      key: 'qtdeEmbarque',
      title: 'Quant.',
      format: GridView.DataTypes.Money,
    },

    {
      key: 'flgCancelado',
      title: 'Canc',
      format: GridView.DataTypes.Boolean,
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
  ];
  const columns14 = [
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'noUFColeta',
      title: 'UF',
    },
    {
      key: 'noCidadeColeta',
      title: 'Local Coleta',
    },
    {
      key: 'noUFEntrega',
      title: 'UF',
    },
    {
      key: 'noCidadeEntrega',
      title: 'Local Entrega',
    },
    {
      key: 'sacado',
      title: 'Sacado',
    },
    {
      key: 'vencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrTit',
      title: 'Nr Tit',
    },
    {
      key: 'vlrTotal',
      title: 'Total Conhecimentos',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrEmAberto',
      title: 'Total Em Aberto',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
  ];
  const columns15 = [
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'dtEmissao',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrNotaFiscal',
      title: 'Nota',
    },
    {
      key: 'pesoBruto',
      title: 'Peso',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrTarifa',
      title: 'Tarifa',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrTotal',
      title: 'Vlr Frete',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'noUFColeta',
      title: 'UF',
    },
    {
      key: 'noCidadeColeta',
      title: 'Local Coleta',
    },
    {
      key: 'noUFEntrega',
      title: 'UF',
    },
    {
      key: 'noCidadeEntrega',
      title: 'Local Entrega',
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
  ];
  const columns16 = [
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'dtEmissao',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    { key: 'noPlaca', title: 'Placa' },
    {
      key: 'noMotorista',
      title: 'Motorista',
    },
    {
      key: 'cdColetaEmbarque',
      title: 'NrCte',
    },
    {
      key: 'statusCTEstr',
      title: 'Status CTE',
      bgClassProperty: 'noColorStatus',
    },
    {
      key: 'nrNotaFiscal',
      title: 'Nota',
    },
    {
      key: 'vlrTotal',
      title: 'Vlr Frete',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrMercadoria',
      title: 'Vlr Mercadoria',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'pesoBruto',
      title: 'Peso',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'noUFColeta',
      title: 'Remetente',
    },
    {
      key: 'noUFEntrega',
      title: 'Destinatario',
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
  ];
  const columns17 = [
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'noUsuario',
      title: 'Usuario',
    },
    {
      key: 'dtEmissao',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    { key: 'noPlaca', title: 'Placa' },
    {
      key: 'cdColetaEmbarque',
      title: 'NrCte',
    },
    {
      key: 'statusCTEstr',
      title: 'Status CTE',
      bgClassProperty: 'noColorStatus',
    },
    {
      key: 'nrChaveCTE',
      title: 'Chave de Acesso',
    },
    {
      key: 'vlrTotal',
      title: 'Vlr Frete',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrMercadoria',
      title: 'Vlr Mercadoria',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
  ];
  const columns18 = [
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'dtEmissao',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    { key: 'noPlaca', title: 'Placa' },
    {
      key: 'cdColetaEmbarque',
      title: 'NrCte',
    },
    {
      key: 'statusCTEstr',
      title: 'Status CTE',
      bgClassProperty: 'noColorStatus',
    },
    {
      key: 'vlrTotal',
      title: 'Vlr Frete',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrMercadoria',
      title: 'Vlr Mercadoria',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'pesoBruto',
      title: 'Peso',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'noRemetente',
      title: 'Remetente',
    },
    {
      key: 'noUFColeta',
      title: 'UF',
    },
    {
      key: 'noCidadeColeta',
      title: 'Local Coleta',
    },
    {
      key: 'noUFEntrega',
      title: 'UF',
    },
    {
      key: 'noCidadeEntrega',
      title: 'Local Entrega',
    },
    {
      key: 'vlrIcms',
      title: 'Vlr Icms',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrFreteTerceiro',
      title: 'Vlr FreteTerceiro',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
  ];
  const columns19 = [
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'dtEmissao',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    { key: 'noPlaca', title: 'Cavalo' },
    {
      key: 'noPlacaComp',
      title: 'Carreta',
    },
    {
      key: 'noMotorista',
      title: 'Motorista',
    },
    {
      key: 'cdCfo',
      title: 'CFOP',
    },
    {
      key: 'cdColetaEmbarque',
      title: 'NrCte',
    },
    {
      key: 'statusCTEstr',
      title: 'Status CTE',
      bgClassProperty: 'noColorStatus',
    },
    {
      key: 'noUFColeta',
      title: 'UF',
    },
    {
      key: 'noCidadeColeta',
      title: 'Origem',
    },
    {
      key: 'noUFEntrega',
      title: 'UF',
    },
    {
      key: 'noCidadeEntrega',
      title: 'Origem',
    },
    {
      key: 'noEspecieFrete',
      title: 'Produto',
    },
    {
      key: 'vlrTotal',
      title: 'Vlr Cte',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'pesoBruto',
      title: 'Peso',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'noCliente',
      title: 'Cliente',
    },
    {
      key: 'noTipoFrete',
      title: 'CIF / FOB',
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
  ];
  const columns20 = [
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'dtEmissao',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    { key: 'noPlaca', title: 'Cavalo' },
    {
      key: 'noPlacaComp',
      title: 'Carreta',
    },
    {
      key: 'cdColetaEmbarque',
      title: 'NrCte',
    },
    {
      key: 'statusCTEstr',
      title: 'Status CTE',
      bgClassProperty: 'noColorStatus',
    },
    {
      key: 'nrNotaFiscal',
      title: 'Nota',
    },
    {
      key: 'vlrTotal',
      title: 'Vlr Frete',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrPedagio',
      title: 'Vlr Pedagio',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrMercadoria',
      title: 'Vlr Mercadoria',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'noMotorista',
      title: 'Motorista',
    },
    {
      key: 'noVendedor',
      title: 'Vendedor',
    },
    {
      key: 'pesoBruto',
      title: 'Peso',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'noRemetente',
      title: 'Remetente',
    },
    {
      key: 'noUFColeta',
      title: 'UF',
    },
    {
      key: 'noCidadeColeta',
      title: 'Local Coleta',
    },
    {
      key: 'noDestinatario',
      title: 'Destinatario',
    },
    {
      key: 'noUFEntrega',
      title: 'UF',
    },
    {
      key: 'noCidadeEntrega',
      title: 'Local Entrega',
    },
    {
      key: 'flgCancelado',
      title: 'Canc',
      format: GridView.DataTypes.Boolean,
    },
    {
      key: 'noTipoFrete',
      title: 'TipoFrete',
    },
    {
      key: 'nrApolice',
      title: 'Apolice',
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
  ];
  const columns21 = [
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'dtEmissao',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    { key: 'noPlaca', title: 'Cavalo' },
    {
      key: 'noPlacaComp',
      title: 'Carreta',
    },
    {
      key: 'cdColetaEmbarque',
      title: 'NrCte',
    },
    {
      key: 'statusCTEstr',
      title: 'Status CTE',
      bgClassProperty: 'noColorStatus',
    },
    {
      key: 'nrNotaFiscal',
      title: 'Nota',
    },
    {
      key: 'vlrTotal',
      title: 'Vlr Frete',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrMercadoria',
      title: 'Vlr Mercadoria',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'noMotorista',
      title: 'Motorista',
    },
    {
      key: 'pesoBruto',
      title: 'Peso',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'noRemetente',
      title: 'Remetente',
    },
    {
      key: 'noUFColeta',
      title: 'UF',
    },
    {
      key: 'noCidadeColeta',
      title: 'Local Coleta',
    },
    {
      key: 'noDestinatario',
      title: 'Destinatario',
    },
    {
      key: 'noUFEntrega',
      title: 'UF',
    },
    {
      key: 'noCidadeEntrega',
      title: 'Local Entrega',
    },
    {
      key: 'cdViagem',
      title: 'Nr Viagem',
    },
    {
      key: 'qtdeEmbarque',
      title: 'Volume',
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
  ];
  const columns22 = [
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'dtEmissao',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    { key: 'noPlaca', title: 'Cavalo' },
    {
      key: 'noPlacaComp',
      title: 'Carreta',
    },
    {
      key: 'cdColetaEmbarque',
      title: 'NrCte',
    },
    {
      key: 'statusCTEstr',
      title: 'Status CTE',
      bgClassProperty: 'noColorStatus',
    },
    {
      key: 'nrNotaFiscal',
      title: 'Nota',
    },
    {
      key: 'vlrIcms',
      title: 'Vlr Icms',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrTotal',
      title: 'Vlr Frete',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrMercadoria',
      title: 'Vlr Mercadoria',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'noMotorista',
      title: 'Motorista',
    },
    {
      key: 'pesoBruto',
      title: 'Peso',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'noRemetente',
      title: 'Remetente',
    },
    {
      key: 'noUFColeta',
      title: 'UF',
    },
    {
      key: 'noCidadeColeta',
      title: 'Local Coleta',
    },
    {
      key: 'noDestinatario',
      title: 'Destinatario',
    },
    {
      key: 'noUFEntrega',
      title: 'UF',
    },
    {
      key: 'noCidadeEntrega',
      title: 'Local Entrega',
    },
    {
      key: 'cdViagem',
      title: 'Nr Viagem',
    },
    {
      key: 'qtdeEmbarque',
      title: 'Volume',
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
  ];
  const columns23 = [
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'dtEmissao',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    { key: 'noPlaca', title: 'Placa' },
    {
      key: 'noMotorista',
      title: 'Motorista',
    },
    {
      key: 'cdColetaEmbarque',
      title: 'NrCte',
    },
    {
      key: 'statusCTEstr',
      title: 'Status CTE',
      bgClassProperty: 'noColorStatus',
    },
    {
      key: 'nrNotaFiscal',
      title: 'Nota',
    },
    {
      key: 'vlrTotal',
      title: 'Vlr Frete',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrMercadoria',
      title: 'Vlr Mercadoria',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'pesoBruto',
      title: 'Peso Transportado',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'noRemetente',
      title: 'Remetente',
    },
    {
      key: 'noUFColeta',
      title: 'UF',
    },
    {
      key: 'noCidadeColeta',
      title: 'Local Coleta',
    },
    {
      key: 'noDestinatario',
      title: 'Destinatario',
    },
    {
      key: 'noUFEntrega',
      title: 'UF',
    },
    {
      key: 'noCidadeEntrega',
      title: 'Local Entrega',
    },
    {
      key: 'qtdeEmbarque',
      title: 'Quant',
    },
    {
      key: 'noEspecieFrete',
      title: 'Produto',
    },
    {
      key: 'flgCancelado',
      title: 'Canc',
      format: GridView.DataTypes.Boolean,
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
  ];
  const columns24 = [
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'dtEmissao',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    { key: 'noPlaca', title: 'Cavalo' },
    {
      key: 'noPlacaComp',
      title: 'Carreta',
    },
    {
      key: 'cdColetaEmbarque',
      title: 'NrCte',
    },
    {
      key: 'statusCTEstr',
      title: 'Status CTE',
      bgClassProperty: 'noColorStatus',
    },
    {
      key: 'nrNotaFiscal',
      title: 'Nota',
    },
    {
      key: 'vlrTotal',
      title: 'Vlr Frete',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrMercadoria',
      title: 'Vlr Mercadoria',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'noMotorista',
      title: 'Motorista',
    },
    {
      key: 'pesoBruto',
      title: 'Peso',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'noCliente',
      title: 'Cliente',
    },
    {
      key: 'noRemetente',
      title: 'Remetente',
    },
    {
      key: 'noUFColeta',
      title: 'UF',
    },
    {
      key: 'noCidadeColeta',
      title: 'Local Coleta',
    },
    {
      key: 'noDestinatario',
      title: 'Destinatario',
    },
    {
      key: 'noUFEntrega',
      title: 'UF',
    },
    {
      key: 'noCidadeEntrega',
      title: 'Local Entrega',
    },
    {
      key: 'noPessoaConsigOri',
      title: 'Consignatário Origem',
    },
    {
      key: 'noPessoaConsigDest',
      title: 'Consignatário Destino',
    },
    {
      key: 'flgCancelado',
      title: 'Canc',
      format: GridView.DataTypes.Boolean,
    },
    {
      key: 'noTipoFrete',
      title: 'TipoFrete',
    },
    {
      key: 'nrApolice',
      title: 'Apolice',
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqViagem',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickOpenViagem(e),
      theme: Theme.Success,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Exibir Viagem',
      tooltipDirection: 'bottom',
    },
  ];
  const columns25 = [
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'dtEmissao',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    { key: 'noPlaca', title: 'Cavalo' },
    {
      key: 'noPlacaComp',
      title: 'Carreta',
    },
    {
      key: 'cdColetaEmbarque',
      title: 'NrCte',
    },
    {
      key: 'statusCTEstr',
      title: 'Status CTE',
      bgClassProperty: 'noColorStatus',
    },
    {
      key: 'nrNotaFiscal',
      title: 'Nota',
    },
    {
      key: 'vlrTotal',
      title: 'Vlr Frete',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrMercadoria',
      title: 'Vlr Mercadoria',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'noMotorista',
      title: 'Motorista',
    },
    {
      key: 'pesoBruto',
      title: 'Peso',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'noCliente',
      title: 'Cliente',
    },
    {
      key: 'noRemetente',
      title: 'Remetente',
    },
    {
      key: 'noUFColeta',
      title: 'UF',
    },
    {
      key: 'noCidadeColeta',
      title: 'Local Coleta',
    },
    {
      key: 'noDestinatario',
      title: 'Destinatario',
    },
    {
      key: 'noUFEntrega',
      title: 'UF',
    },
    {
      key: 'noCidadeEntrega',
      title: 'Local Entrega',
    },
    {
      key: 'noFormaPagamento',
      title: 'Forma Pagamento',
    },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
    },
    {
      key: 'nossoNumero',
      title: 'NossoNumero',
    },
    {
      key: 'flgLiquidado',
      title: 'flgLiquidado',
      format: GridView.DataTypes.Boolean,
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqViagem',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickOpenViagem(e),
      theme: Theme.Success,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Exibir Viagem',
      tooltipDirection: 'bottom',
    },
  ];
  const columns26 = [
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'dtEmissao',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    { key: 'noPlaca', title: 'Cavalo' },
    {
      key: 'nrNotaFiscal',
      title: 'Nota',
    },
    {
      key: 'vlrTotal',
      title: 'Vlr Frete',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrMercadoria',
      title: 'Vlr Mercadoria',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'pesoBruto',
      title: 'Peso',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'qtdeEmbarque',
      title: 'Quant.',
    },
    {
      key: 'noFreteMercadoria',
      title: 'Frete Mercadoria',
    },
    {
      key: 'noMotorista',
      title: 'Motorista',
    },
    {
      key: 'noRemetente',
      title: 'Remetente',
    },
    {
      key: 'noDestinatario',
      title: 'Destinatario',
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqViagem',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickOpenViagem(e),
      theme: Theme.Success,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Exibir Viagem',
      tooltipDirection: 'bottom',
    },
  ];

  return (
    <>
      {nrSeqTipoImpressao === 1 && (
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              enableExcelExport
              gridSizeList={[50000]}
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns1}
              onColumnSort={onColumnSort}
              notAllowChangePage
              showSelectedSizes={false}
              transaction={transaction}
              sumFields={sumFields}
            />
          </div>
        </div>
      )}
      {nrSeqTipoImpressao === 2 && (
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              enableExcelExport
              gridSizeList={[50000]}
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns2}
              onColumnSort={onColumnSort}
              notAllowChangePage
              showSelectedSizes={false}
              transaction={transaction}
              sumFields={sumFields}
            />
          </div>
        </div>
      )}
      {nrSeqTipoImpressao === 3 && (
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              enableExcelExport
              gridSizeList={[50000]}
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns3}
              onColumnSort={onColumnSort}
              notAllowChangePage
              showSelectedSizes={false}
              transaction={transaction}
              sumFields={sumFields}
            />
          </div>
        </div>
      )}
      {nrSeqTipoImpressao === 4 && (
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              enableExcelExport
              gridSizeList={[50000]}
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns4}
              onColumnSort={onColumnSort}
              notAllowChangePage
              showSelectedSizes={false}
              transaction={transaction}
              sumFields={sumFields}
            />
          </div>
        </div>
      )}
      {nrSeqTipoImpressao === 5 && (
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              enableExcelExport
              gridSizeList={[50000]}
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns5}
              onColumnSort={onColumnSort}
              notAllowChangePage
              showSelectedSizes={false}
              transaction={transaction}
              sumFields={sumFields}
            />
          </div>
        </div>
      )}
      {nrSeqTipoImpressao === 6 && (
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              enableExcelExport
              gridSizeList={[50000]}
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns6}
              onColumnSort={onColumnSort}
              notAllowChangePage
              showSelectedSizes={false}
              transaction={transaction}
              sumFields={sumFields}
            />
          </div>
        </div>
      )}
      {nrSeqTipoImpressao === 7 && (
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              enableExcelExport
              gridSizeList={[50000]}
              notAllowChangePage
              showSelectedSizes={false}
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns7}
              onColumnSort={onColumnSort}
              transaction={transaction}
              sumFields={sumFields}
            />
          </div>
        </div>
      )}
      {nrSeqTipoImpressao === 8 && (
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              enableExcelExport
              gridSizeList={[50000]}
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns8}
              onColumnSort={onColumnSort}
              notAllowChangePage
              showSelectedSizes={false}
              transaction={transaction}
              sumFields={sumFields}
            />
          </div>
        </div>
      )}
      {nrSeqTipoImpressao === 9 && (
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              enableExcelExport
              gridSizeList={[50000]}
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns9}
              onColumnSort={onColumnSort}
              notAllowChangePage
              showSelectedSizes={false}
              transaction={transaction}
              sumFields={sumFields}
            />
          </div>
        </div>
      )}
      {nrSeqTipoImpressao === 10 && (
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              enableExcelExport
              gridSizeList={[50000]}
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns10}
              onColumnSort={onColumnSort}
              notAllowChangePage
              showSelectedSizes={false}
              transaction={transaction}
              sumFields={sumFields}
            />
          </div>
        </div>
      )}
      {nrSeqTipoImpressao === 11 && (
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              enableExcelExport
              gridSizeList={[50000]}
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns11}
              onColumnSort={onColumnSort}
              notAllowChangePage
              showSelectedSizes={false}
              transaction={transaction}
              sumFields={sumFields}
            />
          </div>
        </div>
      )}
      {nrSeqTipoImpressao === 12 && (
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              enableExcelExport
              gridSizeList={[50000]}
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns12}
              onColumnSort={onColumnSort}
              notAllowChangePage
              showSelectedSizes={false}
              transaction={transaction}
              sumFields={sumFields}
            />
          </div>
        </div>
      )}
      {nrSeqTipoImpressao === 13 && (
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              enableExcelExport
              gridSizeList={[50000]}
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns13}
              onColumnSort={onColumnSort}
              notAllowChangePage
              showSelectedSizes={false}
              transaction={transaction}
              sumFields={sumFields}
            />
          </div>
        </div>
      )}
      {nrSeqTipoImpressao === 14 && (
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              enableExcelExport
              gridSizeList={[50000]}
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns14}
              onColumnSort={onColumnSort}
              notAllowChangePage
              showSelectedSizes={false}
              transaction={transaction}
              sumFields={sumFields}
            />
          </div>
        </div>
      )}
      {nrSeqTipoImpressao === 15 && (
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              enableExcelExport
              gridSizeList={[50000]}
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns15}
              onColumnSort={onColumnSort}
              notAllowChangePage
              showSelectedSizes={false}
              transaction={transaction}
              sumFields={sumFields}
            />
          </div>
        </div>
      )}
      {nrSeqTipoImpressao === 16 && (
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              enableExcelExport
              gridSizeList={[50000]}
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns16}
              onColumnSort={onColumnSort}
              notAllowChangePage
              showSelectedSizes={false}
              transaction={transaction}
              sumFields={sumFields}
            />
          </div>
        </div>
      )}
      {nrSeqTipoImpressao === 17 && (
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              enableExcelExport
              gridSizeList={[50000]}
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns17}
              onColumnSort={onColumnSort}
              notAllowChangePage
              showSelectedSizes={false}
              transaction={transaction}
              sumFields={sumFields}
            />
          </div>
        </div>
      )}
      {nrSeqTipoImpressao === 18 && (
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              enableExcelExport
              gridSizeList={[50000]}
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns18}
              onColumnSort={onColumnSort}
              notAllowChangePage
              showSelectedSizes={false}
              transaction={transaction}
              sumFields={sumFields}
            />
          </div>
        </div>
      )}
      {nrSeqTipoImpressao === 19 && (
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              enableExcelExport
              gridSizeList={[50000]}
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns19}
              onColumnSort={onColumnSort}
              notAllowChangePage
              showSelectedSizes={false}
              transaction={transaction}
              sumFields={sumFields}
            />
          </div>
        </div>
      )}
      {nrSeqTipoImpressao === 20 && (
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              enableExcelExport
              gridSizeList={[50000]}
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns20}
              onColumnSort={onColumnSort}
              notAllowChangePage
              showSelectedSizes={false}
              transaction={transaction}
              sumFields={sumFields}
            />
          </div>
        </div>
      )}
      {nrSeqTipoImpressao === 21 && (
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              enableExcelExport
              gridSizeList={[50000]}
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns21}
              onColumnSort={onColumnSort}
              notAllowChangePage
              showSelectedSizes={false}
              transaction={transaction}
              sumFields={sumFields}
            />
          </div>
        </div>
      )}
      {nrSeqTipoImpressao === 22 && (
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              enableExcelExport
              gridSizeList={[50000]}
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns22}
              onColumnSort={onColumnSort}
              notAllowChangePage
              showSelectedSizes={false}
              transaction={transaction}
              sumFields={sumFields}
            />
          </div>
        </div>
      )}
      {nrSeqTipoImpressao === 23 && (
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              enableExcelExport
              gridSizeList={[50000]}
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns23}
              onColumnSort={onColumnSort}
              notAllowChangePage
              showSelectedSizes={false}
              transaction={transaction}
              sumFields={sumFields}
            />
          </div>
        </div>
      )}
      {nrSeqTipoImpressao === 24 && (
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              enableExcelExport
              gridSizeList={[50000]}
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns24}
              onColumnSort={onColumnSort}
              notAllowChangePage
              showSelectedSizes={false}
              transaction={transaction}
              sumFields={sumFields}
            />
          </div>
        </div>
      )}
      {nrSeqTipoImpressao === 25 && (
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              enableExcelExport
              gridSizeList={[50000]}
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns25}
              onColumnSort={onColumnSort}
              notAllowChangePage
              showSelectedSizes={false}
              transaction={transaction}
              sumFields={sumFields}
            />
          </div>
        </div>
      )}
      {nrSeqTipoImpressao === 26 && (
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              enableExcelExport
              gridSizeList={[50000]}
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns26}
              onColumnSort={onColumnSort}
              notAllowChangePage
              showSelectedSizes={false}
              transaction={transaction}
              sumFields={sumFields}
            />
          </div>
        </div>
      )}
    </>
  );
}
